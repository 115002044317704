<template>
  <section class="pt-5">
    <CContainer>
      <CRow>
        <CCol :md="{ span: 4, offset: 1 }" class="mb-md-0 mb-4">
          <section-title
            :tagline="tagline"
            :title="title"
            :subtitle="subtitle"
          />
        </CCol>
        <CCol md="6">
          <div class="body-content">
            {{ description }}
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";

export default {
  components: { SectionTitle },
  name: "AboutBody",

  props: ["title", "subtitle", "description"],

  data() {
    return {
      tagline: "About Us",
    };
  },
};
</script>

<style lang="scss" scoped></style>
