<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="12" class="mb-4">
          <swiper-nav
            leftClassName="vGalleryprevArrow"
            RightClassName="vGalleryNextArrow"
          />
        </CCol>
      </CRow>
    </CContainer>

    <swiper
      :breakpoints="breakpoints"
      :autoHeight="false"
      :slides-per-view="5"
      :space-between="50"
      :navigation="{
        nextEl: '.vGalleryprevArrow',
        prevEl: '.vGalleryNextArrow',
      }"
    >
      <swiper-slide v-for="video in videos" :key="video.id">
        <div class="image-card gall-video-item">
          <img alt="" :src="video.image" />
          <div class="overlay"></div>
          <div class="card-content">
            <h4>{{ video.title }}</h4>

            <button
              class="btn video-play-icon"
              @click="() => handleVideoPlay(video)"
            >
              <img src="/img/playicon.png" alt="" />
            </button>
          </div>
        </div>
      </swiper-slide> </swiper
    ><CModal
      alignment="center"
      :visible="visibleVerticallyCenteredDemo"
      @close="
        () => {
          visibleVerticallyCenteredDemo = false;
        }
      "
    >
      <CModalHeader>
        <CModalTitle>{{ currentVideo.title }}</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <iframe
          class="modal-video"
          :src="'https://www.youtube.com/embed/' + currentVideo.videoKey"
          :title="currentVideo.title"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </CModalBody>
    </CModal>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "AboutVideoGallery",
  components: { Swiper, SwiperSlide, SwiperNav },

  data() {
    return {
      visibleVerticallyCenteredDemo: false,

      currentVideo: ref({}),

      breakpoints: {
        0: {
          slidesPerView: 3,
          spaceBetween: 10,
        },

        425: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        767: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1600: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },

      videos: ref([]),
    };
  },

  mounted() {
    this.getVideoDetail();
  },

  methods: {
    handleVideoPlay: function (video) {
      this.visibleVerticallyCenteredDemo = true;
      this.currentVideo = video;
    },

  async getVideoDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/youtube-sliding-content";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let videoGalleryDetail = response.data.data;
          if(response.data.status_code === 200 &&  videoGalleryDetail.length > 0){
            this.currentVideo = {
              id: videoGalleryDetail[0].id,
              title: videoGalleryDetail[0].title,
              image: videoGalleryDetail[0].image_path,
              videoKey: videoGalleryDetail[0].youtube_link,
            };
            Object.entries(videoGalleryDetail).forEach(([key, value]) => {
              this.videos.push({
                id: value.id,
                title: value.title,
                image: value.image_path,
                videoKey: value.youtube_link ?? '',
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
